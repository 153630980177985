export const environment = {
  production: false,
  panel: "radheexch_",
  // domain: "jarvisexch.com",
  // baseUrl: "https://jarvisexch.com",
  domain: "radhexchange.com",
  baseUrl: "https://api.radhexchange.com",
  // domain: "parker777.com",
  // baseUrl: "https://api.parker777.com",
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  imgPrefix: '/radheexch_client',
  guestEnable: false,
  darkMode: false,
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      pusher: {
          key: '',
          cluster: ''
      },
      themePath: [''],
      guestEnable: false,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      afLoginRedirectUrl: '',
      newHomePage: false,
      isCustomBanner: false,
      demoLogin: false,
      desktopBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr5-min-test-img.png'],
      mobileBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr5-min-test-img.png']
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: true,
          desktopBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr5-min-test-img.png'],
          mobileBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr5-min-test-img.png']
      },
      {
          domain: 'radhexchange.com',
          name: 'radhexchange',
          dName: 'radhexchange',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '76d7c55c2b5f8747ffd4',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: true,
          desktopBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr5-min-test-img.png'],
          mobileBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr5-min-test-img.png']
      },
      {
          domain: '11dream247.com',
          name: '11dream247',
          dName: '11dream247',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '76d7c55c2b5f8747ffd4',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: true,
          desktopBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr5-min-test-img.png'],
          mobileBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr5-min-test-img.png']
      },
      {
          domain: 'crex999.com',
          name: 'crex999',
          dName: 'crex999',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: true,
          desktopBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner1.jpg', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner2.jpg', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner3.jpg', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner4.jpg',],
          mobileBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner1.jpg', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner2.jpg', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner3.jpg', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/kanha/banner4.jpg',],
      },
      {
        domain: 'radheexch.me',
        name: 'radheexch',
        dName: 'radheexch',
        ext: '.me',
        theme: 'blue-body',
        email: '',
        pusher: {
            key: '76d7c55c2b5f8747ffd4',
            cluster: 'eu'
        },
        themePath: ['assets/css/theme-files/theme-master.css'],
        guestEnable: true,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        afLoginRedirectUrl: '/dashboard',
        newHomePage: false,
        isCustomBanner: false,
        demoLogin: true,
        desktopBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/desktop/bnr5-min-test-img.png'],
        mobileBannerList: ['https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr1-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr2-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr3-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr4-min-test-img.png', 'https://static-content-banners.s3.ap-south-1.amazonaws.com/radhe/mobile/bnr5-min-test-img.png']
    }
  ]
};
